/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "El modelo de tiendas de aplicaciones en móvil ha traído tanto grandes oportunidades como polémicas. Por un lado tenemos la posibilidad de que un desarrollador de cualquier lugar del mundo lance aplicaciones y pueda vivir de ese trabajo, una gran conquista en la globalización de los pequeños, de esta forma la tienda de aplicaciones se convierte en un ", React.createElement(_components.a, {
    href: "http://lasindias.net/indianopedia/Mumi"
  }, "mumi"), " generando abundancia en torno a la misma tienda. Las polémicas vienen del lado de las consecuencias de tener ese nodo centralizador, el centro impone ", React.createElement(_components.a, {
    href: "http://www.genbeta.com/movil/google-veta-el-reconocimiento-facial-en-glass"
  }, "su política"), " y ", React.createElement(_components.a, {
    href: "http://www.genbeta.com/multimedia/google-glass-no-tendra-apps-porno"
  }, "su ética"), " y el resto apechuga o ", React.createElement(_components.a, {
    href: "http://firefoxos.info/"
  }, "cambia de plataforma"), "."), "\n", React.createElement(_components.p, null, "Podemos usar las ventajas de este modelo y convertir los problemas también en ventajas para las tiendas de objetos físicos, me explico:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Primero crear una tienda donde cualquiera pueda vender el producto que haya diseñado, orientado a usuario final, sin necesidad de ser un gran fabricante y con las ventajas del acceso global y la posible producción externalizada."), "\n", React.createElement(_components.li, null, "Después convertir la desventaja en ventaja, la tienda nunca va a ser neutral, partamos de eso, no busquemos universalismos, nuestra tienda tiene que tener una posición clara de que quiere vender, debe tener ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2013/06/05/para-vender-en-internet-necesitas-un-relato/"
  }, "el relato del que hablábamos"), " y ser coherente con él. Si sólo se aceptan los productos que concuerden con este relato y se genera un diálogo, la tienda dejará de ser un mumi, para convertirse poco a poco en el ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2013/01/10/el-mito-del-hogar-i/"
  }, "hogar"), " figurado de una comunidad."), "\n", React.createElement(_components.li, null, "Por último la tienda podrá prestar servicios a los diseñadores para garantizar su autonomía, podría por ejemplo fabricar algunos productos para disminuir costes de transporte, ofrecer almacenes distribuidos, blogs donde facilitar el relato o cualquier otra cosa."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
